var pawLibrary = {
	map: false,
	loading: "<div id=\"loader\" class=\"center\" style=\"width:100%\"><img src=\"./imgs/loader.gif\" alt=\"Loading...\"  /></div>",
	search: function(term)
	{
		$('#searchresults-wrap').html('<div id="loading">Searching...</div>').load('ajax/search.inc.php?term=' + term);
	},
	validateForm: function()
	{
		var errors = 0;

		var name_txt = $('#name_txt');
		var email_txt = $('#email_txt');
		var message_txt = $('#message_txt');
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if(name_txt.val().length < 3)
		{
			name_txt.addClass('required_border');
			errors++;
		} else {
			name_txt.removeClass('required_border');
		}

		if( !filter.test(email_txt.val()) )
		{
			email_txt.addClass('required_border');
			errors++;
		} else {
			email_txt.removeClass('required_border');
		}

		if(message_txt.val().length < 3)
		{
			message_txt.addClass('required_border');
			errors++;
		} else {
			message_txt.removeClass('required_border');
		}

		return (errors == 0) ? true : false;

	},

	getTwitterProfilePic: function(elemId, username)
	{

		$.ajax({
			type: 'json',
		    url: '../ajax/getTwitter.inc.php?username=' + username,
		    beforeSend: function() {
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="./imgs/loader.gif" alt="'+username+'" class="loader" />');
		    },
		    success: function(data){
		    	var img = data[0].image;
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="'+img+'" alt="'+username+'" class="profile" />');
		    }
		});

	},

	generateMap: function(elem,lat,lng,zoomLevel) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: zoomLevel,
		  center: myLatlng,
      scrollwheel: false,
			draggable: true,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
		}

		this.map = new google.maps.Map(document.getElementById(elem), myOptions);
	},

	addMarker: function(lat, lng, location_title, location_add) {
		var myLatlng = new google.maps.LatLng(lat,lng);

		var contentString = '<div id="mapContent" class="infoMarker">'+
				'<h4>' + location_title + '</h4>'+
				'<p>' + location_add + '</p>'+
				'<a class="btn-red" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: this.map,
			icon: './imgs/marker.png',
			title: location_title
		});

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(this.map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});
	},
	centerMap: function() {
		var center = this.map.getCenter();
		this.map.setCenter();
	},

	setMap : function(elem, lat, lng, location_title, location_add, drag) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 14,
		  center: myLatlng,
      scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: drag
		}

		var map = new google.maps.Map(document.getElementById(elem), myOptions);

		var contentString = '<div id="mapContent">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			title: location_title,
			draggable: drag
		});

		if(drag == true) {
			google.maps.event.addListener(marker, 'dragend', function() {
					var point = marker.position;
					if(lat_txt) lat_txt.value = point.$a;
					if(long_txt) long_txt.value = point.ab;

				});
		}

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});

	},

	setMapByGeocode : function(elemId, address, zoomlevel) {

		var mapOptions = {
		  zoom: zoomlevel,
          scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: false
		}

		var contentString = '<div id="mapContent">'+
				'<p>'+address+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(address)+'">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
            content: contentString
         });

		var geocoder = new google.maps.Geocoder();
		var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

		geocoder.geocode( {
			'address': address
				}, function(results, status) {
					  if (status == google.maps.GeocoderStatus.OK) {
						map.setCenter(results[0].geometry.location);
						var marker = new google.maps.Marker({
							map: map,
							position: results[0].geometry.location
						});

						google.maps.event.addListener(marker, 'click', function() {
						  infowindow.open(map,marker);
						});

					  }
					}
		);

	},

	mobileNav: function()
	{
		var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var pageBody = $('#page-wrap-inner');
		var secondMenu = mobileNavItems.find('ul.second');
		if( mobileBtn.length > 0 )
		{
			// Load Menu
			mobileBtn.click(function() {
				if(mobileBtn.hasClass('menu-open') == true) {
					pageBody.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					pageBody.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').unbind().click(function(e) {
				var clicked = e.currentTarget.id.replace("showMenu","");
				var target = mobileNavItems.find('#subMenu' + clicked);
				
				if(target.hasClass('open') == false) {
					secondMenu.slideUp(function() {
						secondMenu.removeClass('open');
					});

					target.slideDown(function() {
						target.addClass('open');
					});
				} else {
					secondMenu.slideUp(function() {
						secondMenu.removeClass('open');
					});
				}
			});
		}

		$('#mobile-nav-inner-items').hcSticky({
			bottom:0
		});

	},

	setGallery: function()
	{

		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 2000);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn" class="rightBtn"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn" class="leftBtn"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	banners: function() {
		var banners = $('#banners');

		if(banners.length > 0) {
			banners.slick({
				dots: true,
			  infinite: true,
			  speed: 500,
			  fade: true,
				nextArrow: '<span id="rightBannerBtn" class="rightBannerBtn"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
				prevArrow: '<span id="leftBannerBtn" class="leftBannerBtn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
			  cssEase: 'linear',
        appendDots: '.banner-paging .inner-wrap',
				slide: '.item'
			});
		}
	},

	validateEmail: function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
	},

	setGallery: function()
	{
		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn-gallery').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 1600);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn-gallery" class="rightBtn-gallery"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn-gallery" class="leftBtn-gallery"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	jumpToLinks: function()
	{
		$('a[href^=#]').click(function(e){
    	var name = $(this).attr('href').substr(1);
    	var pos = $('#'+name).offset();
    	$('body').animate({ scrollTop: pos.top });
    	e.preventDefault();
  	});
	},

	dropdownMenus: function()
	{

		$('.parent > a').on('click', function(e) {
			var selected = $(this);
			var id = selected.attr('id');
			if(id != '') id = id.replace('showMenu','');
			var hasSubMenu = ($('#subMenu' + id).length == 1) ? true : false;

			if(hasSubMenu == true)
			{
				e.preventDefault();

				// Show
				if(selected.hasClass('selected') == true) {
					// Hide
					selected.removeClass('selected');
					$('.parent-sub').hide();
				} else {
					// Show
					selected.addClass('selected');
					$('.parent-sub').hide();
					$('.parent a').removeClass('selected');
					$('#subMenu' + id).fadeIn();
					$('#showMenu' + id).addClass('selected');
				}
			}
		});
	},

	getUrlParameter: function(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
	},

	searchCalendar: function() {
		var blank = $('#blank');
		$('#search_txt').on('keyup', function() {
			var term = $(this).val().toLowerCase();
			var items = $('ul#searchItems li');
			var found = false;

			$.each(items, function(key, row) {
				var row = $(row);
				var title = row.find('a')[0].text.toLowerCase();
				var cat = $(row.find('.tag')[0]).html().toLowerCase();
				var month = $(row.find('.cal-mon-search')[0]).html().toLowerCase();

				if((title.indexOf(term) > -1) || (cat.indexOf(term) > -1) || (month.indexOf(term) > -1)) {
					// found
					row.removeClass('hide');
					found = true;
				} else {
					// not found
					row.addClass('hide');
				}
			});

			if(found == false) {
				blank.removeClass('hide');
			} else {
				blank.addClass('hide');
			}


		});
	},
	stickSubNav: function() {
			$('#stick-wrapper').stickem();
	},

	init: function()
	{
		pawLibrary.mobileNav();
		pawLibrary.banners();
		pawLibrary.jumpToLinks();

		var fancyBox = $('.fancybox');
		if(fancyBox.length > 0) {
			var selected_item = 0;
			var selected_item_details = {'img': '', 'desc': ''};
			var template = "";
			fancyBox.fancybox({
				afterLoad  : function() {
		      var tmp = this.element.context.id;
					selected_item = tmp.replace("item", "");
					selected_item_details.img = $('#item' + selected_item + ' img').attr('src');
					selected_item_details.desc = $('#item' + selected_item + ' .desc').html();
					template = '<img src="' + selected_item_details.img + '" />';
					template += '<div>' + selected_item_details.desc + '</div>';
					template += '<a class="btn yellow" href="./contact">Contact us to order parts</a>';
					$('.fancybox-inner').html(template).css('height', 'auto');
		    },
				padding: 0,
				openEffect  : 'none',
		    closeEffect : 'none',
				width: '90%',
				tpl: {
		      closeBtn: '<a title="Close" class="fancybox-item fancybox-close myClose" href="javascript:;"><i class="fa fa-times" aria-hidden="true"></i></a>'
		    }
			});
		}

		var base = $('base').attr('href');
		$('#mobile-sub-menu').on('change', function() {
			var value = $(this).val().replace("./", "");
			window.location = base + value;
		});


		pawLibrary.dropdownMenus();

		if($('#search_txt').length > 0) {
			pawLibrary.searchCalendar();
		}

	}

}

pawLibrary.init();

$(window).resize(function() {
	if($('ul[data-cycle-fx="carousel"]').length > 0)
	{
		$('ul[data-cycle-fx="carousel"]').cycle('reinit');
	}
});

$(window).load(function() {
	var banners = $("#slideshow-inner");
	if(banners.length > 0) {
		pawLibrary.setGallery(banners);
	}
	pawLibrary.stickSubNav();
});

$('a[href*="#"]:not([href="#"])').click(function() {
  if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    if (target.length) {
      $('html, body').animate({
        scrollTop: target.offset().top
      }, 1000);
      return false;
    }
  }
});
